import { mean } from "lodash";
import {
  FC,
  createContext,
  useState,
  ReactNode,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { getPose } from "src/app/getPose";
import { calcBestPerson } from "src/app/logic/pose/bestPersonDetector";
import { settingKeys } from "src/app/logic/pose/poseConstants";
import { processVideoPose } from "src/app/logic/pose/poseProcessor";
import {
  ResultRo,
  SettingDto,
  TaskRo,
  useMediaControllerGetPoseUrlQuery,
} from "src/app/services/generatedApi";
import { categoryDefaultSettings } from "src/components/molecules/category/constants";
import { Angle3DResultRo, FramePose, VideoPose } from "src/types/pose";
import { roundAngles } from "src/utils/chartsUtils";
import { isWithinThreshold } from "src/utils/numericUtils";

export enum BodyPartState {
  SAFE = "SAFE",
  HAZARD = "HAZARD",
  CAUTION = "CAUTION",
  NONE = "NONE",
}

type TaskContextType = {
  setting: SettingDto;
  havePose: boolean;
  duration: number;
  fps: number;
  selectedAngles3D: Angle3DResultRo[];
  selectedAngles: ResultRo[];
  videoPose?: VideoPose;
  setDuration: (seconds: number) => void;
  calcSelectedPartState: (
    selectedPart: keyof SettingDto,
    currentTime?: number,
    personId?: number,
  ) => BodyPartState;
  calcAllPartState: (
    currentTime?: number,
    personId?: number,
  ) => { [key in keyof SettingDto]?: BodyPartState };
  calcSelectedPartStateCount: (selectedPart: keyof SettingDto) => {
    [key in BodyPartState]: number;
  };
  isSited: boolean;
  smoothedVideoPose?: VideoPose;
  openNotes: boolean;
  smoothing: boolean;
  selectedPersonId: number | (number | undefined)[];
  onChangeSelectedPersonId: (personId?: number) => void;
  changeSelectedPersonIdIfNeeded: (
    personId: number,
    frameIndex: number,
  ) => void;
  setOpenNotes: (openNotes: boolean) => void;
};

export const TaskContext = createContext<TaskContextType>({
  setting: categoryDefaultSettings,
  havePose: false,
  duration: 0,
  fps: 30,
  selectedAngles3D: [],
  selectedAngles: [],
  setDuration: () => {},
  calcSelectedPartState: () => BodyPartState.SAFE,
  isSited: false,
  openNotes: false,
  smoothing: true,
  calcAllPartState: () => ({}),
  calcSelectedPartStateCount: () => ({
    [BodyPartState.SAFE]: 0,
    [BodyPartState.CAUTION]: 0,
    [BodyPartState.HAZARD]: 0,
    [BodyPartState.NONE]: 0,
  }),
  selectedPersonId: [],
  onChangeSelectedPersonId: () => {},
  changeSelectedPersonIdIfNeeded: () => {},
  setOpenNotes: () => {},
});

type TaskContextProviderPropsType = {
  task: TaskRo;
  children?: ReactNode;
};

const TaskContextProvider: FC<TaskContextProviderPropsType> = ({
  task,
  children,
}) => {
  const [videoPose, setVideoPose] = useState<VideoPose>([]);
  const [duration, setDuration] = useState(0);
  const [selectedPersonId, setSelectedPersonId] = useState<
    number | (number | undefined)[]
  >([]);
  const [smoothedVideoPose, setSmoothedVideoPose] = useState<FramePose[]>([]);
  const [openNotes, setOpenNotes] = useState(false);

  const { data: videoPoseUrl } = useMediaControllerGetPoseUrlQuery(
    { taskId: task.id },
    { skip: !task.havePose },
  );

  const framesCount = useMemo(
    () => videoPose.length || task.result?.length || 0,
    [task.result, videoPose],
  );

  const fps = useMemo(
    () => (duration === 0 ? 30 : framesCount / duration),
    [framesCount, duration],
  );

  const aiPersonId = useMemo(
    () => (videoPose?.length > 0 ? calcBestPerson(videoPose) : []),
    [videoPose],
  );

  const getState = useCallback(
    (selectedPart: keyof SettingDto, angle?: number): BodyPartState => {
      if (!task.setting || !angle) {
        return BodyPartState.NONE;
      }

      const { threshold1, threshold2, threshold3, threshold4 } =
        task.setting[selectedPart];

      if (
        isWithinThreshold(angle, threshold1, threshold2) ||
        isWithinThreshold(angle, threshold3, threshold4)
      ) {
        return BodyPartState.CAUTION;
      }
      if (isWithinThreshold(angle, threshold2, threshold3)) {
        return BodyPartState.SAFE;
      }
      return BodyPartState.HAZARD;
    },
    [task.setting],
  );

  const calcSelectedPersonAngles = useCallback(
    (personId: number | (number | undefined)[]): ResultRo[] =>
      smoothedVideoPose.length > 0
        ? smoothedVideoPose.map((framePose, frameIndex) => {
            const pid =
              typeof personId === "number" ? personId : personId[frameIndex];
            const selectedPersonPose = framePose.find(
              (personPose) => personPose.id === pid,
            );
            return selectedPersonPose?.angles || {};
          })
        : roundAngles(task.result || []),
    [smoothedVideoPose, task.result],
  );

  const selectedAngles = useMemo<ResultRo[]>(
    () => calcSelectedPersonAngles(selectedPersonId),
    [calcSelectedPersonAngles, selectedPersonId],
  );

  const calcSelectedPersonAngles3D = useCallback(
    (personId: number | (number | undefined)[]): Angle3DResultRo[] =>
      smoothedVideoPose.map((framePose, frameIndex) => {
        const pid =
          typeof personId === "number" ? personId : personId[frameIndex];
        const selectedPersonPose = framePose.find(
          (personPose) => personPose.id === pid,
        );
        return selectedPersonPose?.angles3D || {};
      }),
    [smoothedVideoPose],
  );

  const selectedAngles3D = useMemo<Angle3DResultRo[]>(
    () => calcSelectedPersonAngles3D(selectedPersonId),
    [calcSelectedPersonAngles3D, selectedPersonId],
  );

  const isSited = useMemo<boolean>(() => {
    return getState("back", 90) !== BodyPartState.HAZARD;
  }, [getState]);

  const calcAllPartState = useCallback(
    (
      currentTime?: number,
      personId?: number,
    ): { [key in keyof SettingDto]?: BodyPartState } => {
      const angles =
        personId === undefined
          ? selectedAngles
          : calcSelectedPersonAngles(personId);

      return settingKeys.reduce(
        (prev, selectedPart) => {
          prev[selectedPart] = getState(
            selectedPart,
            currentTime === undefined
              ? mean(
                  angles
                    ?.map((item) => item?.[selectedPart])
                    .filter((item) => item !== undefined),
                )
              : angles?.[Math.round(currentTime * fps)]?.[selectedPart],
          );
          return prev;
        },
        {} as { [key in keyof SettingDto]?: BodyPartState },
      );
    },
    [calcSelectedPersonAngles, selectedAngles, fps, getState],
  );

  const calcSelectedPartStateCount = useCallback(
    (selectedPart: keyof SettingDto): { [key in BodyPartState]: number } => {
      const counts = {
        [BodyPartState.SAFE]: 0,
        [BodyPartState.CAUTION]: 0,
        [BodyPartState.HAZARD]: 0,
        [BodyPartState.NONE]: 0,
      };
      selectedAngles?.forEach((item) => {
        const state = getState(selectedPart, item?.[selectedPart]);
        counts[state]++;
      });
      return counts;
    },
    [selectedAngles, getState],
  );

  const calcSelectedPartState = useCallback(
    (
      selectedPart: keyof SettingDto,
      currentTime?: number,
      personId?: number,
    ): BodyPartState => {
      const angles =
        personId === undefined
          ? selectedAngles
          : calcSelectedPersonAngles(personId);

      if (currentTime === undefined) {
        const counts = calcSelectedPartStateCount(selectedPart);
        const sum =
          counts[BodyPartState.HAZARD] +
          counts[BodyPartState.CAUTION] +
          counts[BodyPartState.SAFE];
        if (sum * 4 < counts[BodyPartState.NONE]) {
          return BodyPartState.NONE;
        }
        const score =
          (counts[BodyPartState.HAZARD] + 0.5 * counts[BodyPartState.CAUTION]) /
          sum;

        return score > 0.66
          ? BodyPartState.HAZARD
          : score > 0.33
            ? BodyPartState.CAUTION
            : BodyPartState.SAFE;
      }

      const angle = angles?.[Math.round(currentTime * fps)]?.[selectedPart];

      return getState(selectedPart, angle);
    },
    [
      selectedAngles,
      calcSelectedPersonAngles,
      fps,
      getState,
      calcSelectedPartStateCount,
    ],
  );

  const onChangeSelectedPersonId = useCallback(
    (personId?: number) =>
      personId === undefined
        ? setSelectedPersonId(aiPersonId)
        : setSelectedPersonId(personId),
    [aiPersonId, setSelectedPersonId],
  );

  const changeSelectedPersonIdIfNeeded = useCallback(
    (personId: number, frameIndex: number) =>
      setSelectedPersonId((prev) => {
        if (prev === personId || typeof prev === "number") {
          return personId;
        } else if (prev[frameIndex] === personId) {
          return prev;
        }
        return prev;
      }),
    [],
  );

  useEffect(() => {
    if (!videoPoseUrl) return;
    getPose(videoPoseUrl).then((res) => setVideoPose(res));
  }, [videoPoseUrl]);

  useEffect(() => {
    if (aiPersonId.length === 0) return;
    setSelectedPersonId(aiPersonId);
  }, [aiPersonId]);

  useEffect(() => {
    let active = true;
    load();
    return () => {
      active = false;
    };

    async function load() {
      if (!active) {
        return;
      }
      setSmoothedVideoPose(
        videoPose?.length > 0
          ? processVideoPose(videoPose, task.useCameraZ)
          : [],
      );
    }
  }, [videoPose, task.useCameraZ]);

  return (
    <TaskContext.Provider
      value={{
        setting: task.setting,
        havePose: task.havePose,
        duration,
        fps,
        selectedAngles3D,
        selectedAngles,
        setDuration,
        calcAllPartState,
        calcSelectedPartState,
        calcSelectedPartStateCount,
        smoothedVideoPose,
        openNotes,
        isSited,
        smoothing: smoothedVideoPose?.length === 0 && task.havePose,
        selectedPersonId,
        onChangeSelectedPersonId,
        changeSelectedPersonIdIfNeeded,
        setOpenNotes,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export default TaskContextProvider;
