import React, { FC, useState } from "react";
import { Paper, Tabs, Tab, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import Template from "src/components/templates/Template";
import Loading from "src/components/atoms/Loading";
import { AddVideoLimitReached } from "src/components/organisms/addVideo/LimitReached";
import {
  useCategoryControllerGetAllCategoriesQuery,
  useTasksControllerGetUserTagsForCurrentUserQuery,
  useUsersControllerCheckUserLimitReachedQuery,
} from "src/app/services/generatedApi";
import SingleVideoUpload from "src/components/organisms/addVideo/Single/SingleVideoUpload";
import MultipleVideoUpload from "src/components/organisms/addVideo/Multiple/MultipleVideoUpload";
import NoCategoriesMessage from "src/components/molecules/AddVideo/NoCategoriesMessage";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const AddVideo: FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  const { data: allCategories, isLoading: getCategoryLoading } =
    useCategoryControllerGetAllCategoriesQuery();
  const { data: allTags, isLoading: getTagsLoading } =
    useTasksControllerGetUserTagsForCurrentUserQuery();
  const { data: limitResult, isLoading: checkLimitLoading } =
    useUsersControllerCheckUserLimitReachedQuery(null as any, {
      refetchOnMountOrArgChange: 10,
    });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (getCategoryLoading || getTagsLoading || checkLimitLoading) {
    return (
      <Template>
        <Loading />
      </Template>
    );
  }

  if (limitResult?.reached) {
    return <AddVideoLimitReached />;
  }

  if (allCategories && allCategories.categories.length < 1) {
    return (
      <Template>
        <NoCategoriesMessage />
      </Template>
    );
  }

  return (
    <Template>
      <Paper
        elevation={isMd ? undefined : 0}
        sx={{
          width: "100%",
          p: 1,
          borderRadius: { xs: 0, md: 1 },
        }}
      >
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Single Video Upload" />
          <Tab label="Multiple Video Upload" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <SingleVideoUpload
            allCategories={allCategories}
            allTags={allTags?.tags}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <MultipleVideoUpload
            allCategories={allCategories}
            allTags={allTags?.tags}
          />
        </TabPanel>
      </Paper>
    </Template>
  );
};

export default AddVideo;
